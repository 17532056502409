/**
 * @copyright 2023 JetCode, SIA
 * @author    Kirill Hainovsky <ksxauh@gmail.com>
 */
class FormFields {
    constructor() {
        $('body').on('change', '[data-affected-fields]', this.refreshRenderTypeField.bind(this));
    }

    refreshRenderTypeField(e) {
        const $sourceField = $(e.currentTarget),
            affectedFields = JSON.parse($sourceField.attr('data-affected-fields'));

        const fieldsMap = {};

        if (Array.isArray(affectedFields)) {
            for (let fieldKey of affectedFields) {
                let fieldId = FormFields.getFieldId(fieldKey);
                let $field  = $(fieldId).closest('.sonata-ba-field');

                new KTBlockUI($field[0]).block();

                fieldsMap[fieldKey] = $field;
            }
        } else {
            for (let fieldKey in affectedFields) {
                let fieldContainer = $(affectedFields[fieldKey]);

                new KTBlockUI(fieldContainer[0]).block();

                fieldsMap[fieldKey] = fieldContainer;
            }
        }

        let data = {};
        const $form = $sourceField.closest('form');
        data[$sourceField.attr('name')] = $sourceField.val();

        $.ajax({
            url: Admin.get_config('refresh_fields_url'),
            type: $form.attr('method'),
            data: data,
            complete: function (html) {
                const $response = $(html.responseText);

                if (Array.isArray(affectedFields)) {
                    for (let fieldKey of affectedFields) {
                        let $responseField  = $response.find(FormFields.getFieldId(fieldKey));
                        let $fieldContainer = $responseField.closest('.sonata-ba-field');

                        fieldsMap[fieldKey].replaceWith($fieldContainer);

                        if ($responseField.attr('data-control') === 'select2') {
                            $responseField.select2();
                        }
                    }
                } else {
                    for (let fieldKey in affectedFields) {
                        let $fieldContainer = $response.find(affectedFields[fieldKey]);

                        fieldsMap[fieldKey].replaceWith($fieldContainer);
                    }
                }
            }
        });
    }

    static getFieldId(fieldKey) {
        return `#${Admin.get_config('admin_uniqid')}_${fieldKey}`;
    }
}

KTUtil.onDOMContentLoaded(function () {
    new FormFields();
});
