'use strict';

const Admin = {
    shared_setup(subject) {
        Admin.setup_xeditable(subject);
    },

    setup_xeditable(subject) {
        Admin.log('[core|setup_xeditable] configure xeditable on', subject);
        jQuery('.x-editable', subject).editable({
            emptyclass: 'editable-empty btn btn-sm',
            emptytext: '<i class="bi bi-pencil-square"></i>',
            container: 'body',
            placement: 'auto',
            success(response) {
                const html = jQuery(response);
                Admin.setup_xeditable(html);
                jQuery(this).closest('td').replaceWith(html);
            },
            error: (xhr) => {
                // On some error responses, we return JSON.
                if (xhr.getResponseHeader('Content-Type') === 'application/json') {
                    return JSON.parse(xhr.responseText);
                }

                return xhr.responseText;
            },
        });
    },

    read_config() {
        const data = jQuery('[data-sonata-admin]').data('sonata-admin');

        this.config = data.config;
        this.translations = data.translations;
    },

    get_config(key) {
        if (this.config == null) {
            this.read_config();
        }

        return this.config[key];
    },

    /**
     * render log message
     * @param mixed
     */
    log(...args) {
        if (!Admin.get_config('DEBUG')) {
            return;
        }

        const msg = `[Sonata.Admin] ${Array.prototype.join.call(args, ', ')}`;
        if (window.console && window.console.log) {
            window.console.log(msg);
        } else if (window.opera && window.opera.postError) {
            window.opera.postError(msg);
        }
    },
};

// Initialize on document ready
KTUtil.onDOMContentLoaded(function () {
    Admin.shared_setup(document);
});

// Declare Admin for Webpack support
if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = Admin;
}
